.feature-individual {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 1rem;
}

.feature-title {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  min-width: 25rem;
  max-width: 25rem;
}

.feature-info {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding-top: 1.35rem;
}

.feature-info > * + * {
  padding-top: 1.35rem;
}
