@import "./variables";
.admin-container {
  display: flex;
  flex-direction: column;
}

.admin-body-container {
  margin-top: 1.5rem;
}

.nav-container {
  flex-grow: 1;
  min-width: 16rem;
  max-width: 16rem;
}

.um-colored-box-outer {
  background: #fff;
  border-radius: 0.3125rem;
  margin-bottom: 1.25rem;
}

.um-colored-box-outer .colored-box-inner {
  margin: 0.625rem;
}

/* nav styling */
.nav {
  button {
    text-align: left !important;
    background: transparent;
  }

  &.nav-pills {
    li {
      button {
        border-bottom: 0.25rem solid transparent;
        border-radius: 0;
        color: $brand-gray-100;
        margin-right: 2.5rem;
        padding: 0 0 0.5rem 0;

        &:hover,
        &:focus {
          background-color: white;
          border-bottom: 0.25rem solid $primary-100;
          color: $primary-100;
        }
      }

      &.active {
        button,
        button:hover,
        button:focus {
          background-color: white;
          border-bottom: 0.25rem solid $primary-100;
          color: $primary-100;
        }
      }
    }

    &.nav-stacked {
      li {
        button {
          border: none;
          border-left: 0.25rem solid transparent;
          color: $brand-gray-100;
          padding: 0.5rem 0.625rem;
          width: 100%;

          &:hover {
            background-color: $primary-10;
            color: inherit;
          }
        }

        &.active {
          button,
          button:hover {
            background-color: $primary-10;
            border-bottom: none;
            border-left: 4px solid $primary-100;
            padding: 0.5rem 0.625rem;
            width: 100%;
          }
        }
      }
    }
  }
}
