.admin-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.admin-body-container {
  margin-top: 1.5rem;
}

.nav-container {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  min-width: 16rem;
  max-width: 16rem;
}

.um-colored-box-outer {
  background: #fff;
  border-radius: 0.3125rem;
  margin-bottom: 1.25rem;
}

.um-colored-box-outer .colored-box-inner {
  margin: 0.625rem;
}

/* nav styling */
.nav button {
  text-align: left !important;
  background: transparent;
}

.nav.nav-pills li button {
  border-bottom: 0.25rem solid transparent;
  border-radius: 0;
  color: #2e2e33;
  margin-right: 2.5rem;
  padding: 0 0 0.5rem 0;
}

.nav.nav-pills li button:hover, .nav.nav-pills li button:focus {
  background-color: white;
  border-bottom: 0.25rem solid #0069ff;
  color: #0069ff;
}

.nav.nav-pills li.active button,
.nav.nav-pills li.active button:hover,
.nav.nav-pills li.active button:focus {
  background-color: white;
  border-bottom: 0.25rem solid #0069ff;
  color: #0069ff;
}

.nav.nav-pills.nav-stacked li button {
  border: none;
  border-left: 0.25rem solid transparent;
  color: #2e2e33;
  padding: 0.5rem 0.625rem;
  width: 100%;
}

.nav.nav-pills.nav-stacked li button:hover {
  background-color: #f3f7ff;
  color: inherit;
}

.nav.nav-pills.nav-stacked li.active button,
.nav.nav-pills.nav-stacked li.active button:hover {
  background-color: #f3f7ff;
  border-bottom: none;
  border-left: 4px solid #0069ff;
  padding: 0.5rem 0.625rem;
  width: 100%;
}
